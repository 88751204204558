@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    font-family: 'Lato', sans-serif;
}


.sms-form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    text-align: center;
    width:400px;
    margin:0px auto;
    height:100vh;
}
.sms-form__header {
    font-size: 23px;
}

.sms-form__label {
    text-align: center;
}

.sms-form__input {
    text-align: center; 
}

.sms-form__submit {
    text-align: center; 
    margin: 10px 10px;
}







body {
	background:#3FA9F5;
	overflow: hidden;
}

.form-container {
	display:flex;
	justify-content: center;
	align-items: center;
	height:100vh;
}

form {
	flex-basis: 70vh;
	padding: 2em;
	background: #fff;
	font-family:Montserrat;
	box-shadow:0 0 2em rgba(0,0,0,0.2);
	border-radius:0.25em;
	
}

input, select {
	padding:0.5em;
	font-size:0.875em;
	font-family:inherit;
	background:#f4f4f4;
	border:1px solid #ccc;
	border-width:1px;
    border-radius:0;
    text-align: center;
}

input:focus,
select:focus {
	background:#fff;
	box-shadow:inset 0.1em 0.1em 0.25em rgba(0,0,0,0.2);
	outline:0;
}

.form-field {
	margin-bottom:1em;
	display:flex;
}

.form-field label {
	flex:0 1 4em;
	
	padding:1em;
	background:#fff;
	border:1px solid #ccc;
	font-size:0.75em;
	text-align:center;
	text-shadow:0 1px 0 #fff;
	line-height:1;
}

.form-field input,
.form-field select {
	flex-grow:1;
	
}

.form-field label {
	border-radius:0.25em 0 0 0.25em;
}
.input-right input,
.input-right select,
.multi-input input:last-child,
.multi-input select:last-child {
	border-radius:0 0.25em 0.25em 0;
}
.dob-select{
	flex-basis: 0.2vh;
}
.sms-from__submit__container {
    flex-direction: row-reverse;
    margin-top: 20px;
}

input[type="submit"] {
	flex:0 1 40%;
	
	padding:0.8em;
	font-size:1.2rem;
	font-family:inherit;
	text-shadow:0 1px 0 #fff;
    background:#3FA9F5;
    color:#fff;
	border-radius:0.25em;
	border:0;
	transition:0.3s;
	cursor:pointer;
}



*:focus {
    outline: none;
   }
